import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { Chat } from "../../components/Chat";
import { Card } from "../../components/Card";
import { ReactComponent as ArrowLeft } from '../../assets/ArrowLeft.svg'

const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel nulla ultricies, pulvinar ex a, pharetra velit. Phasellus tempus augue ac lacus interdum molestie. Proin eget malesuada quam. In tempus auctor sodales. Nam posuere, ipsum eleifend scelerisque fermentum, neque augue egestas diam, in fermentum nunc sem et nisi. Fusce pharetra ultrices ligula a convallis. Etiam a elementum mi, non molestie mauris. Fusce et tortor interdum, dictum dui vulputate, iaculis turpis. Mauris a metus sed tortor pretium condimentum ut quis leo. Nam feugiat consectetur dolor et facilisis. In risus libero, tincidunt vitae elit sit amet, venenatis suscipit enim.'

const ChatPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate()

    const [chatHistory, setChatHistory] = useState<
        { sender: "me" | "ai"; message: string, images: { url: string, name: string }[] }[]
    >([]);
    const [aiIsTyping, setAiIsTyping] = useState(false);

    const addMessage = ({ text, images }: { text: string, images: { url: string, name: string }[] }) => {
        setChatHistory((prevState) => [
            ...prevState,
            { sender: "me", message: text, images },
        ])

        setAiIsTyping(true)

        setTimeout(() => {
            setChatHistory((prevState) => [
                ...prevState,
                { sender: "ai", message: lorem, images: [] },
            ])
            setAiIsTyping(false)
        }, 2000)
    }

    console.log(chatHistory)

    return (
        <div
            style={{
                display: "flex",
                height: "100vh", // Prevent body scroll
                overflow: "hidden", // No overflow outside the container
            }}
        >
            {/* Sidebar */}
            <aside
                style={{
                    width: "250px",
                    backgroundColor: "#f4f4f4",
                    position: "sticky",
                    top: "0",
                    height: "100vh",
                    overflowY: "auto", // Scroll only if sidebar content overflows
                }}
            >
                <Sidebar />
            </aside>

            {/* Main Content */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1, // Takes up remaining space
                    overflow: "hidden", // Prevents body overflow
                }}
            >
                {/* Navbar */}
                <nav
                    style={{
                        height: "60px",
                        backgroundColor: "#fff",
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 16px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        flexShrink: 0, // Prevent navbar from shrinking
                    }}
                >
                    <h3>LOGO</h3>
                </nav>

                {/* Scrollable Content */}
                <div
                    style={{
                        backgroundColor: "#fff",
                        padding: '20px 0px',
                        overflowY: "auto",
                        scrollbarGutter: "stable", // Ensures stable layout
                        boxSizing: "border-box",
                        position: 'relative'
                    }}
                >
                    <div style={{ position: 'fixed', display: 'flex', flexDirection: 'column', gap: '24px', padding: '8px 20px', width: '196px' }}>
                        <div style={{ display: 'flex', gap: '4px', cursor: 'pointer' }} onClick={() => { navigate('/') }}>
                            <ArrowLeft />
                            <p style={{ fontWeight: 400, fontSize: '12px', color: '#A4A4A4' }}>Go back</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <p style={{ fontWeight: 400, fontSize: '12px', color: '#666666', }}>Chat:</p>
                            <Card>
                                <p style={{ padding: '8px 12px', fontWeight: 500, fontSize: '12px', cursor: 'pointer' }}>What would happen if I cut the sales ops from ‘W1 to W4, Dec’ due to large demand?</p>
                            </Card>
                        </div>
                    </div>
                    {/* Long Content */}
                    <div style={{
                        width: 640,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        minHeight: 'calc(100vh - 60px - 182px - 40px - 24px)'
                    }}>

                        {chatHistory.map(({ sender, message, images }) =>
                            <Card style={sender === 'me' ? { backgroundColor: '#F1F1F1', alignSelf: 'flex-end', wordBreak: 'break-word' } : { border: 'none', alignSelf: 'flex-start', wordBreak: 'break-word' }}>
                                <p style={{ padding: '16px 20px', fontWeight: 500, fontSize: '14px', ...(sender === 'me' ? { textAlign: 'end' } : { textAlign: 'start' }) }}>{message}</p>
                                {images.map(({ url, name }) => <div style={{ display: 'flex', margin: '20px', flexDirection: 'column', alignItems: 'flex-end', gap: '8px' }}>
                                    <p style={{ fontWeight: 400, fontSize: '11px', color: '#666666' }}>{name}</p>
                                    <img style={{ borderRadius: '8px' }} src={url} alt="asd" />
                                </div>)}
                            </Card>
                        )}

                    </div>
                </div>

                {/* Footer */}
                <footer
                    style={{
                        backgroundColor: "#fff",
                        flexShrink: 0, // Prevent footer from shrinking
                        overflowY: 'auto',
                        scrollbarGutter: "stable",
                    }}
                >
                    <div style={{
                        width: 640,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingBottom: '24px'
                    }}>
                        <Chat
                            onSubmit={addMessage}
                        />
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default ChatPage;
