import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import { Sidebar } from "../../components/Sidebar";
import { ReactComponent as Sparkling } from "../../assets/Sparkling.svg"
import { Card } from "../../components/Card";
import { Chat } from "../../components/Chat";
import { SimulationSuggestion } from "../../components/SimulationSuggestion";
import { ReactComponent as ChevronDown } from '../../assets/ChevronDown.svg'
import { ReactComponent as Trash } from '../../assets/Trash.svg'
import AnimateHeight from "react-animate-height";

const DashboardPage = () => {

    const navigate = useNavigate()
    const [height, setHeight] = useState<0 | 'auto'>(0);

    return <div style={{ display: 'flex', minHeight: '100vh' }}>
        <aside>
            <Sidebar />
        </aside>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', paddingTop: '120px' }}>
            <div style={{ width: 640, display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '12px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                        <Sparkling color="#4692FC" width={34} height={34} />
                        <p style={{ fontSize: 28, fontWeight: 600 }}>Run simulations</p>
                    </div>
                    <Chat onSubmit={() => {
                        navigate('/chat/1')
                    }} />
                    <Card>
                        <div onClick={() => setHeight(height === 0 ? 'auto' : 0)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '14px 16px', cursor: 'pointer' }}>
                            <div style={{ display: 'flex', gap: '7px' }}>
                                <Trash />
                                <p style={{ fontWeight: 500, fontSize: 13 }}>Previous chats</p>
                            </div>
                            <div style={{ transform: height ? "rotate(180deg)" : undefined, height: '16px', width: '16px' }}>
                                <ChevronDown />
                            </div>
                        </div>
                        <AnimateHeight
                            id="example-panel"
                            duration={500}
                            height={height} // see props documentation below
                        >
                            <div style={{ padding: '14px 16px', }}>
                                <p>Chat one</p>
                                <p>Chat two</p>
                                <p>Chat three</p>
                            </div>
                        </AnimateHeight>
                    </Card>
                </div>
                <SimulationSuggestion />
            </div>
        </div>
    </div>
}

export default DashboardPage