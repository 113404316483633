import React, { useRef, useState } from "react";
import { Card } from "../Card";
import { styles } from "./Chat.styles";
import { ReactComponent as PaperClip } from '../../assets/PaperClip.svg'
import { ReactComponent as Database } from '../../assets/Database.svg'
import { ReactComponent as Code } from '../../assets/Code.svg'
import { ReactComponent as DownloadFile } from '../../assets/DownloadFile.svg'
import { ReactComponent as Close } from '../../assets/Close.svg'
import { ReactComponent as SendIcon } from '../../assets/SendIcon.svg'
import { PillButton } from "../PillButton";
import { ChatProps } from "./Chat.interface";

export const Chat = ({ onSubmit }: ChatProps) => {

    const [text, setText] = useState('')
    const inputImageRef = useRef<HTMLInputElement>(null)

    const [images, setImage] = useState<{ url: string, name: string }[]>([]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Get the file from the input

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage((prev) => [...prev, { url: reader.result as string, name: file.name }]); // Set the image URL for display
            };
            reader.readAsDataURL(file); // Read the file as a Data URL (base64 encoded)
        }
    };

    const handleOnSubmit = ({ text, images }: { text: string, images: { url: string, name: string }[] }) => {
        if (!text) return;
        onSubmit({ text, images })
        setText('')
        setImage([])
    }

    return <Card>
        <div className={styles.chat}>

            <div className={styles.content}>
                <textarea className={styles.textArea} value={text} placeholder="Ask about your supply chain" onChange={(e) => setText(e.target.value)} onKeyDown={(e) => {
                    if (!text) {
                        return
                    }

                    if (e.key === 'Enter') {
                        if (e.shiftKey) {
                            e.preventDefault();
                            setText(prev => prev + '\n')
                            return
                        }
                        e.preventDefault();
                        handleOnSubmit({ text, images })
                    }
                }} />
                <input ref={inputImageRef} style={{ display: 'none' }} type="file"
                    accept=".png"
                    onChange={(e) => handleImageUpload(e)} />
            </div>
            <div className={styles.footer}>
                <div className={styles.iconContainer}>
                    <PaperClip cursor='pointer' onClick={() => { inputImageRef.current?.click() }} />
                    <Database cursor='pointer' />
                    <Code cursor='pointer' />
                </div>
                <div className={styles.divider} />
                <div className={styles.iconContainer}>
                    <PillButton label="Context: from builder" prefix={<DownloadFile />} variant="primary" />
                    <PillButton label="Context: from builder" suffix={<Close />} variant="secondary" />
                    <div onClick={() => {
                        handleOnSubmit({ text, images })
                    }} style={{ cursor: 'pointer', height: '32px', width: '32px', backgroundColor: '#000000', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', marginLeft: 'auto' }}>
                        <SendIcon />
                    </div>
                </div>
            </div>
        </div>
    </Card>
}